import { productIsEligibleForSwap, isEligibleForSwap } from "~/helpers/ecommerce/hardwareOrderHelper";
export default {
	props: {
		productProp: {
			type: Object,
		},
	},
	computed: {
		eCommerceProduct() {
			return this.productProp || this.$store.state.ecommerce.product;
		},
		showSwap() {
			return (
				this.eCommerceProduct?.categories?.some((item) => productIsEligibleForSwap(item)) ||
				isEligibleForSwap(this.eCommerceProduct?.category)
			);
		},
		eCommerceProductType() {
			return this.eCommerceProduct?.category === "Nettbrett" ? "Nettbrett" : "Mobil";
		},
	},
};
